import React, { useState } from 'react';

import ShareIcon from './ShareIcon';

const Header = ({ session, setEditProfile, setPointsModal }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  function toggle() {
    setToggleMenu(!toggleMenu);
  }

  function onEditProfile() {
    setEditProfile(true);
    toggle();
  }

  function onEditPoints() {
    setPointsModal(true);
    toggle();
  }

  return (
    <div className='header'>
      <h2>Let's Point It!</h2>
      <ShareIcon />

      <div className='settings'>
        <button type='button' className='settings-button' onClick={toggle}>
          Settings
        </button>
        <ul
          className={`settings-menu ${
            toggleMenu ? 'settings-menu--open' : 'settings-menu--closed'
          }`}>
          <li onClick={onEditProfile}>Edit Profile</li>
          <li onClick={onEditPoints}>Edit Points</li>
        </ul>
        <div
          onClick={toggle}
          className={`menu-backdrop ${
            toggleMenu ? 'menu-backdrop-display' : 'menu-backdrop-hide'
          }`}></div>
      </div>
    </div>
  );
};
export default Header;
