import React from 'react';

const Attendee = ({ attendee, style, isUser, displayHand }) => (
  <div className='flex-card attendee-card' style={style}>
    <div className='card-display'>
      {displayHand && (
        <div className='card'>
          <div className={isUser ? 'card-border' : ''}>
            {attendee.point || ''}
          </div>
        </div>
      )}
      {!displayHand && (
        <div className='card card--hide'>
          <div className='card-pattern'></div>
        </div>
      )}
    </div>
    <div className='attendee'>
      <div className='overlay'></div>
      <span>
        {attendee.display_name} {isUser ? '(You)' : ''}
      </span>
    </div>
  </div>
);
export default Attendee;
