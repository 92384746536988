import React, { useState } from 'react';

import handButtonIcon from '../../assets/handButtonIcon.svg';
import { choosePointCard } from '../../firebase/firestore';

const Hand = ({ session, user }) => {
  const [toggleHand, setToggleHand] = useState(false);

  function toggleHandPanel() {
    setToggleHand(!toggleHand);
  }

  function setPoint(point) {
    if (user) {
      choosePointCard(user, point, session);
    }
    toggleHandPanel();
  }

  function pointCards() {
    return session.pointSystem.map((point, index) => (
      <div className='point-card' key={index} onClick={() => setPoint(point)}>
        {point}
      </div>
    ));
  }

  const handBackdropState = toggleHand
    ? 'hand-backdrop--open'
    : 'hand-backdrop--closed';

  const handPanelState = toggleHand ? 'hand-panel--open' : 'hand-panel--closed';

  return (
    <>
      <div
        className={`hand-backdrop ${handBackdropState}`}
        onClick={toggleHandPanel}></div>
      <div className={`hand-panel ${handPanelState}`}>{pointCards()}</div>
      <div className='hand-button' onClick={toggleHandPanel}>
        <img src={handButtonIcon} alt='Choose a Point' />
      </div>
    </>
  );
};
export default Hand;
