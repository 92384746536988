const dev = {
  url: 'http://dev.letspointit.app'
};

const prod = {
  url: 'http://letspointit.app'
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  ...config
};