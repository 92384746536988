const dev = {
  apiKey: 'AIzaSyBtpgb-u2qARlabDR_KKGSJkOW8dlQY340',
  authDomain: 'pointit-dev.firebaseapp.com',
  databaseURL: 'https://pointit-dev.firebaseio.com',
  projectId: 'pointit-dev',
  storageBucket: 'pointit-dev.appspot.com',
  messagingSenderId: '40704879491',
  appId: '1:40704879491:web:8a49668a9890b3047f64b6',
  measurementId: 'G-E2REZMMPXS'
};

const prod = {
  apiKey: 'AIzaSyBfPa-ThgbFdY5Q2tmUoXtHZwjUSei7IQU',
  authDomain: 'lets-point-it-app.firebaseapp.com',
  databaseURL: 'https://lets-point-it-app.firebaseio.com',
  projectId: 'lets-point-it-app',
  storageBucket: 'lets-point-it-app.appspot.com',
  messagingSenderId: '893696254209',
  appId: '1:893696254209:web:f2e3e6f0066ba1cf1dfb00',
  measurementId: 'G-SL65QFSDKQ',
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  ...config
};