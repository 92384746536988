import React, { useState } from 'react';

import { updatePointsForSession } from '../../firebase/firestore';

const EditPoints = ({ setPointsModal, session }) => {
  const [points, setPoints] = useState(session.pointSystem);

  function cancelEdit() {
    setPointsModal(false);
  }

  function handleSubmit(event) {
    updatePointsForSession(session, points).then(() =>
      setPointsModal(false)
    );
    event.preventDefault();
  }

  function updatePoints(event) {
    setPoints(event.target.value.split(','));
  }

  return (
    <div className='edit-points-modal modal--full-screen'>
      <div className='cancel-edit' onClick={cancelEdit}>
        X
      </div>
      <form className='edit-points-form' onSubmit={handleSubmit}>
        <div className='row'>
          <label htmlFor='edit-points'>Edit Points</label>
          <input
            type='text'
            placeholder='1, 2, 3, 5, 8...'
            value={points}
            onChange={updatePoints}
          />
        </div>
        <button type='submit'>Update Points</button>
      </form>
    </div>
  );
};
export default EditPoints;
