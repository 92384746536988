import React, { useContext, useState } from 'react';
import { TwitterPicker } from 'react-color';

import { updateUserInSession } from '../../firebase/firestore';
import { UserContext } from '../../hooks/user.context';

const EditProfile = ({ session, setEditProfile, buttonLabel }) => {
  const { user } = useContext(UserContext);
  const [profile, setProfile] = useState({
    color: '#005082',
    display_name: 'Anonimoose',
    role: 'participant',
    ...user,
  });

  const colors = [
    '#005082',
    '#844685',
    '#204051',
    '#216353',
    '#84142d',
    '#ffa259',
    '#eceba7',
    '#ff8a5c',
    '#8bbabb',
    '#9c2c2c',
  ];

  const userNotInSession = !!session.attendees[user.id];

  function updateColor(color) {
    setProfile({
      ...profile,
      color: color.hex,
    });
  }

  function updateName(event) {
    setProfile({
      ...profile,
      display_name: event.target.value,
    });
  }

  function updateRole(event) {
    setProfile({
      ...profile,
      role: event.target.value,
    });
  }

  function cancelEdit() {
    setEditProfile(false);
  }

  function handleSubmit(event) {
    updateUserInSession(user, profile, session).then(() => {
      setEditProfile(false);
    });
    event.preventDefault();
  }

  return (
    <div className='edit-profile-modal modal--full-screen'>
      {userNotInSession && (
        <div className='cancel-edit' onClick={cancelEdit}>
          X
        </div>
      )}
      <form className='edit-profile-form' onSubmit={handleSubmit}>
        <label>Name</label>
        <input
          type='text'
          placeholder='Anonimoose'
          onChange={updateName}
          value={profile.display_name}
        />
        <label>Color</label>
        <TwitterPicker
          colors={colors}
          triangle='hide'
          color={profile.color}
          onChangeComplete={updateColor}
        />
        <label>Role</label>
        <div className='row'>
          <div className='role-option'>
            <input
              type='radio'
              id='participant'
              name='role'
              value='participant'
              onChange={updateRole}
              checked={profile.role === 'participant'}
            />
            <label className='inline-label' htmlFor='participant'>
              Participant
            </label>
          </div>
          <div className='role-option'>
            <input
              type='radio'
              id='observer'
              name='role'
              value='observer'
              onChange={updateRole}
              checked={profile.role === 'observer'}
            />
            <label className='inline-label' htmlFor='observer'>
              Observer
            </label>
          </div>
        </div>
        <button type='submit'>{buttonLabel}</button>
      </form>
    </div>
  );
};
export default EditProfile;
