import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getSession } from '../../firebase/firestore';
import { UserContext } from '../../hooks/user.context';
import Body from './Body';
import EditPoints from './EditPoints';
import EditProfile from './EditProfile';
import Hand from './Hand';
import Header from './Header';

const SessionPage = () => {
  const { sessionId } = useParams();
  const [session, setSession] = useState(null);
  const { user } = useContext(UserContext);
  const [editProfile, setEditProfile] = useState(true);
  const [pointsModal, setPointsModal] = useState(false);

  useEffect(() => {
    const listener = (sessionId) => {
      getSession(sessionId).onSnapshot((session) => {
        setSession({ ...session.data(), id: session.id });
      });
    };
    if (sessionId) {
      listener(sessionId);

      return () => listener();
    }
  }, [sessionId]);

  const sessionLoaded = !!session;
  const userLoaded = !!user;

  return (
    <>
      {(!sessionLoaded || !userLoaded) && <>LOADING</>}
      {sessionLoaded && userLoaded && (
        <div className='session-page'>
          {!editProfile && (
            <>
              <Header
                session={session}
                setEditProfile={setEditProfile}
                setPointsModal={setPointsModal}
              />
              <Body session={session} user={user} />
            </>
          )}

          {editProfile && (
            <EditProfile
              session={session}
              setEditProfile={setEditProfile}
              buttonLabel={
                !!session.attendees[user.id] ? 'Update Profile' : 'Join Session'
              }
            />
          )}

          {pointsModal && (
            <EditPoints setPointsModal={setPointsModal} session={session} />
          )}

          <Hand session={session} user={user} />
        </div>
      )}
    </>
  );
};
export default SessionPage;
