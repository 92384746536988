import React from 'react';

export const UserContext = React.createContext({
  user: undefined,
});

export const initialUser = {
  display_name: '',
  color: '',
  id: null,
};
